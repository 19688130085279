import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'active', name: 'Active' },
	{ id: 'paused', name: 'Paused' },
	{ id: 'done', name: 'Done' },
	{ id: 'maintenance', name: 'Maintenance' },
	{ id: 'cancelled', name: 'Cancelled' },
	{ id: 'late', name: 'Late' },
	{ id: 'blocked', name: 'Blocked' },
];

const StatusInput = ({ classes, source = 'status', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusInput;
