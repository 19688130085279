// TODO: Remove this file when weeklies and monthlies are validated
const data = {
    "weeklies": [
        {
            id: 1,
            user_id: 2,
            user: { id: 2, fullname: 'Hélène Quoitot' },
            workload: 'low',
            priorities: '<ul><li><p>Venn Portal</p></li><li><p>LRE</p></li><li><p>Kernel</p></li></ul>',
            tasks: '<p>Europe Remembers</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Tickets à suivre</p></div></li></ul><p></p><p>LRE</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Tickets à suivre</p></div></li><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Application mobile</p></div></li></ul><p></p><p>Marketing</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Textes à rédiger pour les posts</p></div></li><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>FrogStrip</p></div></li></ul><p></p><p>VENN</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Améliorations Portal</p></div></li></ul><p></p><p>Kernel</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Leaves</p></div></li><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Timesheet</p></div></li></ul><p></p><p>R&amp;D</p><ul data-type=\"taskList\"><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>NextJS</p></div></li><li data-checked=\"false\" data-type=\"taskItem\"><label><input type=\"checkbox\"><span></span></label><div><p>Tailwind avec React</p></div></li></ul>',
            notes: null,
            created_at: '2024-10-25T10:41:16.062379+00:00'
        },
        {
            id: 2,
            user_id: 1,
            user: { id: 1, fullname: 'Khalid Yagoubi' },
            workload: 'overloaded',
            priorities: '<ul><li><p>Tout</p></li></ul>'
        },
    ],
    "monthlies": [
        {
            id: 1,
            priorities: '<ul><li><p>Venn Portal</p></li></ul>',
            tasks: "<p>Venn</p><ul><li><p>Site Survey en production</p></li><li><p>Portal</p></li><li><p>StarProxy Backend</p></li><li><p>Starlink Events</p></li><li><p>Connecteur avec nouvel opérateur brésilien</p></li></ul><p></p><p>ITAV</p><ul><li><p>Audit + Fonctionnalités à proposer au client</p></li></ul><p></p><p>DUC</p><ul><li><p>Copybox WOL</p></li><li><p>ERPRINT</p></li><li><p>Amélioration de l'UX à la DUC</p></li><li><p>List 3 things to improve at DUC</p></li></ul><p></p><p>IKOAB</p><ul><li><p>SEO</p></li><li><p>Amélioration Ikosupport</p></li><li><p>StartIA</p></li></ul><p></p><p>LRE</p><ul><li><p>LRE IA - Préparation</p></li><li><p>LRE Apscheduler</p></li><li><p>LRE Mobile</p></li></ul><p></p><p>Backstage</p><ul><li><p>Matchmaking (due date 17/10)</p></li><li><p>Rencontrer Thomas pour un point FRB / Backstage</p></li></ul><p>Venn web</p><ul><li><p>Mise en ligne</p></li></ul><p></p><p>Cushman</p><ul><li><p>RiskLedger - Update</p></li><li><p>Mise en production publique</p></li></ul><p></p><p>Sagacify</p><ul><li><p>Site web</p></li></ul><p></p><p>Inseetu</p><ul><li><p>Rendre estimation</p></li><li><p>Update Backend</p></li><li><p>Update Frontend</p></li></ul><p></p><p>Autre</p><ul><li><p>Kickoff Rodelle / Delsart</p></li></ul><p></p><p>Prospection</p><ul><li><p>Sagora</p></li><li><p>Meetern</p></li><li><p>Digital First (17/10/2024)</p></li></ul><p></p><p>Marketing</p><ul><li><p>Présentation Wagtail externe</p></li></ul><p></p><p>SWAG</p><ul><li><p>Finalisation / Documentation</p></li></ul><p></p><p>KernDB</p><ul><li><p>Mise en production</p></li></ul><p></p><p>Security</p><ul><li><p>First sections in Kern Security Policy</p></li></ul>",
            created_at: '2024-10-01T10:41:16.062379+00:00'
        }
    ],
    "suggestions": [
        {
            id: 1,
            title: 'Ajouter une boîte à idées',
            description: '<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint delectus blanditiis eum pariatur veritatis voluptates earum magnam modi.</p><p>Soluta illum, sequi odio esse magni delectus fugiat repellendus. Ad, in sed.</p>',
            category: 'kernel',
            user_id: 5,
            user: {
                id: 5,
                fullname: 'Céline Mourrier'
            },
            status: 'new',
            votes: [
                { id: 1, is_upvote: true, user_id: 2, user: { id: 2, trigram: 'HQU' } },
                { id: 2, is_upvote: true, user_id: 3, user: { id: 3, trigram: 'GMA' } },
                { id: 3, is_upvote: false, user_id: 1, user: { id: 1, trigram: 'KYA' } },
            ],
            comments: [
                { id: 1, user_id: 2, suggestion_id: 1, content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ullam ipsa libero praesentium minima dolorem perferendis! Voluptate rerum, beatae, voluptatem natus repellat architecto eveniet vitae perferendis dicta placeat ea debitis.', user: { id: 2, fullname: 'Hélène Quoitot', color: '#369787', trigram: 'HQU' } },
                { id: 2, user_id: 1, suggestion_id: 1, content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ullam ipsa libero praesentium minima dolorem perferendis! Voluptate rerum, beatae, voluptatem natus repellat architecto eveniet vitae perferendis dicta placeat ea debitis.', user: { id: 1, fullname: 'Khalid Yagoubi', color: '#8455a7', trigram: 'KYA' } }
            ],
            created_at: '2024-11-21T10:41:16.062379+00:00'
        },
        {
            id: 2,
            title: 'Faire un teambuilding karaoké',
            description: '',
            category: 'team',
            user_id: 1,
            user: {
                id: 1,
                fullname: 'Khalid Yagoubi'
            },
            status: 'new',
            votes: [
                { id: 1, is_upvote: false, user_id: 2, user: { id: 2, trigram: 'HQU' } },
                { id: 2, is_upvote: false, user_id: 3, user: { id: 3, trigram: 'GMA' } },
                { id: 3, is_upvote: false, user_id: 4, user: { id: 4, trigram: 'DCR' } },
                { id: 3, is_upvote: false, user_id: 5, user: { id: 5, trigram: 'CMO' } },
                { id: 3, is_upvote: false, user_id: 6, user: { id: 6, trigram: 'MMR' } },
                { id: 3, is_upvote: false, user_id: 7, user: { id: 7, trigram: 'JDU' } },
                { id: 3, is_upvote: false, user_id: 8, user: { id: 8, trigram: 'BPE' } },
                { id: 3, is_upvote: false, user_id: 9, user: { id: 9, trigram: 'EHV' } },
            ],
            comments: [
                { id: 1, user_id: 2, suggestion_id: 1, content: 'Lorem ipsum', user: { id: 2, fullname: 'Hélène Quoitot', color: '#369787', trigram: 'HQU' } }
            ],
            created_at: '2024-11-22T10:41:16.062379+00:00'
        }
    ]
};

export default data;
