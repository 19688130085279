import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';

import Edit from '../common/views/Edit';
import RichTextInput, { FullRichTextInputToolbar } from '../common/inputs/RichTextInput';
import CategoryInput from './inputs/CategoryInput';

const SuggestionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <CategoryInput />
            <TextInput source="title" fullWidth sx={{ maxWidth: '100%' }} />
            <RichTextInput source="description" toolbar={<FullRichTextInputToolbar />} />
        </SimpleForm>
    </Edit>
);

export default SuggestionEdit;
