import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';


const TypeField = ({ source = 'type' }) => {
	const record = useRecordContext();
	const translate = useTranslate();

	const types = {
		'leave': translate('Leave'),
		'recup': translate('Recup'),
		'sick': translate('Sick leave'),
		'unpaid': translate('Unpaid leave'),
		'other': translate('Other')
	};

	if(!record) {
		return null;
	}

	const key = record ? record[source] : null;

	return (
		<Typography variant="body2">{types[key]}</Typography>
	);
};

export default TypeField;
