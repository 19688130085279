import React from 'react';
import { Button, useRecordContext, useGetIdentity, useRefresh } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { upvoteSuggestion, downvoteSuggestion } from '../../../services/api';

const VoteField = ({ size }) => {
    const { data: account } = useGetIdentity();
    const record = useRecordContext();
    const refresh = useRefresh();

    const sxButton = size ? (
        {
            fontSize: size,
            '& span': {
                display: 'flex', alignItems: 'center',
                '& svg': { fontSize: `${size}px !important` }
            }
        }
    ) : {};
    let hasVotedPositive, hasVotedNegative = false;
    const positiveVotes = record?.votes?.filter(vote => {
        if (vote?.user_id === account?.id && vote.is_upvote) {
            hasVotedPositive = true;
        }
        return vote.is_upvote;
    });
    const negativeVotes = record?.votes?.filter(vote => {
        if (vote?.user_id === account?.id && !vote.is_upvote) {
            hasVotedNegative = true;
        }
        return !vote.is_upvote;
    });

    const handleUpvote = async () => {
        const response = await upvoteSuggestion(record.id);
        if (response.success) {
            refresh();
        }
    };

    const handleDownvote = async () => {
        const response = await downvoteSuggestion(record.id);
        if (response.success) {
            refresh();
        }
    };

    return (
        <>
            <Tooltip title={positiveVotes?.map(item => item?.user?.trigram)?.join(', ')}>
                <span>
                    <Button color={positiveVotes > negativeVotes ? "success" : "inherit"} onClick={handleUpvote} sx={sxButton}>
                        {hasVotedPositive ? (
                            <ThumbUpAltIcon sx={{ marginRight: 1 }} />
                        ) : (
                            <ThumbUpOffAltIcon sx={{ marginRight: 1 }} />
                        )}
                        {positiveVotes?.length || 0}
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={negativeVotes?.map(item => item?.user?.trigram)?.join(', ')}>
                <span>
                    <Button color={negativeVotes > positiveVotes ? "error" : "inherit"} onClick={handleDownvote} sx={sxButton}>
                        {hasVotedNegative ? (
                            <ThumbDownAltIcon sx={{ marginRight: 1 }} />
                        ) : (
                            <ThumbDownOffAltIcon sx={{ marginRight: 1 }} />
                        )}
                        {negativeVotes?.length || 0}
                    </Button>
                </span>
            </Tooltip>
        </>
    );
};

export default VoteField;