import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

import { fetchUserStats } from '../../services/api';

const ACHIEVEMENTS_LEVELS = {
    time_entries: {
        days: [10, 100, 200, 1000, 2000],
        longer_days: [50],
        meetings: [20, 150, 300, 1000, 2000],
        calls: [20, 150, 300, 1000, 2000],
        long_meetings: [10]
    },
    projects: [3, 6, 10, 20, 50],
    leaves: {
        sick: [1]
    },
    weeklies: [10, 50, 100, 150, 300]
};

const Achievement = ({ name, value = 0, description, levels, icon }) => {
    let progress = 0;
    let level = 0;
    let active = false;
    let max;

    levels.every((item, index) => {
        max = item;
        if (value <= item) {
            progress = (value / item) * 100;
            if (value === item) {
                active = true;
                if (levels.length >= index + 1) {
                    max = levels[index + 1];
                    progress = (value / max) * 100;
                }
            }
            else {
                level--;
            }
            return false;
        }
        active = true;
        if (levels.length <= index + 1) {
            return false;
        }
        level++;
        return true;
    });

    if (!active) {
        return null;
    }

    return (
        <Grid item xs={6} sm={4} md={3} lg={2}>
            <Tooltip title={description}>
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="space-between" sx={{ opacity: active ? 1 : 0.3, height: '100%' }}>
                    <Box sx={{ '& img': { width: '50%' }, marginBottom: '15px', position: 'relative' }} display="flex" justifyContent="center">
                        {icon}
                        {level >= 1 && (
                            <img alt="" src={`/img/achievements/frames/${level}.png`} style={{ position: 'absolute' }} />
                        )}
                    </Box>
                    <Typography gutterBottom align="center">{name}</Typography>
                    {levels?.length > 1 && (
                        <Box px={4} sx={{ width: '100%' }} display="flex" flexDirection="column" alignItems="center">
                            <LinearProgress value={progress} variant="determinate" sx={{ width: '100%' }} />
                            <Typography variant="caption">{value}/{max}</Typography>
                        </Box>
                    )}
                </Box>
            </Tooltip>
        </Grid>
    );
};

const AchievementsGrid = ({ userId }) => {
    const [stats, setStats] = useState();
    const translate = useTranslate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchUserStats(userId);
                if (response) {
                    setStats(response);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId]);

    return (
        <Box p={3}>
            <Grid container spacing={2}>
                <Achievement
                    icon={<img src="/img/achievements/projects.png" alt="" />}
                    name={translate('More work?')}
                    value={stats?.projects}
                    levels={ACHIEVEMENTS_LEVELS.projects}
                    description={<Typography variant="caption">{translate('Involved in many projects')}</Typography>}
                />
                <Achievement
                    icon={<img src="/img/achievements/time_entries_days.png" alt="" />}
                    name="Tempus fugit"
                    value={stats?.time_entries?.days}
                    levels={ACHIEVEMENTS_LEVELS.time_entries.days}
                    description={
                        <>
                            <Typography variant="caption" display="block" gutterBottom>{translate('Has filled his timesheet many times')}</Typography>
                            <Typography variant="caption" display="block">{translate('Time is an illusion. Lunchtime doubly so.')}</Typography>
                        </>
                    }
                />
                <Achievement
                    icon={<img src="/img/achievements/time_entries_meetings.png" alt="" />}
                    name={translate('Pain reinforces devotion')}
                    value={stats?.time_entries?.meetings}
                    levels={ACHIEVEMENTS_LEVELS.time_entries.meetings}
                    description={<Typography variant="caption">{translate('Took part in many meetings')}</Typography>}
                />
                <Achievement
                    icon={<img src="/img/achievements/time_entries_meetings.png" alt="" />} // TODO: add icon
                    name={translate('Hang by a thread')}
                    value={stats?.time_entries?.calls}
                    levels={ACHIEVEMENTS_LEVELS.time_entries.calls}
                    description={<Typography variant="caption">{translate('Took part in many calls')}</Typography>}
                />
                <Achievement
                    icon={<img src="/img/achievements/leaves_sick.png" alt="" />}
                    name={translate('Disciple of Nurgle')}
                    value={stats?.leaves?.sick}
                    levels={ACHIEVEMENTS_LEVELS.leaves.sick}
                    description={<Typography variant="caption">{translate('Took one day\'s sick leave')}</Typography>}
                />
                <Achievement
                    icon={<img src="/img/achievements/leaves_sick.png" alt="" />} // TODO: add icon
                    name={translate('Marathoner')}
                    value={stats?.time_entries?.long_meetings}
                    levels={ACHIEVEMENTS_LEVELS.time_entries.long_meetings}
                    description={<Typography variant="caption">{translate('Underwent very long meetings')}</Typography>}
                />
                <Achievement
                    icon={<img src="/img/achievements/time_entries_days.png" alt="" />} // TODO: add icon
                    name="Grenouille de bénitier"
                    value={stats?.time_entries?.longer_days}
                    levels={ACHIEVEMENTS_LEVELS.time_entries.longer_days}
                    description={<Typography variant="caption" display="block" gutterBottom>{translate('Has filled his timesheet many times with more than 8 hours a day')}</Typography>}
                />
            </Grid>
        </Box>
    );
};

export default AchievementsGrid;