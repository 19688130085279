import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import orange from '@mui/material/colors/orange';

import ListIcon from '@mui/icons-material/List';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		display: 'inline-block',
		width: '100%',
		textDecoration: 'none',
		border: '1px solid #E0E0E0',
		boxShadow: 'none !important'
	},
	content: {
		alignItems: 'center',
		display: 'flex'
	},
	title: {
		color: theme.palette.text.secondary,
		fontWeight: 700,
		paddingRight: '10px'
	},
	value: {
		marginTop: theme.spacing(),
		color: theme.palette.text.primary,
	},
	iconWrapper: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		display: 'inline-flex',
		height: '4rem',
		justifyContent: 'center',
		marginLeft: 'auto',
		width: '4rem',
		'& svg': {
			color: theme.palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem'
		}
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: '2rem',
		height: '2rem',
		width: '2rem'
	}
}));

const paperRoundedSx = {
	border: '1px solid #E0E0E0',
	borderRadius: '20px',
	boxShadow: 'none !important'
};

const smallPaperSx = { padding: '12px !important' };
const iconWrapperSmallSx = { width: '3.5rem !important', height: '3.5rem !important' };
const warningIconSx = { backgroundColor: `${orange[500]} !important` };
const dividerSx = { marginTop: '16px' };
const contentSx = { margin: '0 -24px', marginBottom: '-24px' };

const IconCard = ({ title, value, icon = <ListIcon />, variant, size, warning, color, content, linkTo, children, ...props }) => {
	const classes = useStyles();

	let paperSx = { ...props.sx };
	let iconWrapperSx = {};
	if (props.paperSx) {
		paperSx = { ...paperSx, ...props.paperSx };
	}
	if (variant === 'rounded') {
		paperSx = { ...paperSx, ...paperRoundedSx };
	}
	if (size === 'small') {
		paperSx = { ...paperSx, ...smallPaperSx };
		iconWrapperSx = { ...iconWrapperSx, ...iconWrapperSmallSx };
	}
	if (warning) {
		iconWrapperSx = { ...iconWrapperSx, ...warningIconSx };
	}
	if (color) {
		iconWrapperSx = { ...iconWrapperSx, backgroundColor: `${color} !important` };
	}

	const renderCardContent = () => (
		<div className={classes.content}>
			<div className={classes.details}>
				<Typography className={classes.title} variant="body1">{title}</Typography>
				{value ? <Typography className={classes.value} variant="h4">{value}</Typography> : null}
				{children}
			</div>
			{icon && (
				<Box className={classes.iconWrapper} sx={iconWrapperSx}>
					{icon}
				</Box>
			)}
		</div>
	);

	return (
		<Paper className={classes.root} sx={paperSx}>
			{linkTo ? <Link to={linkTo} sx={{ textDecoration: 'none' }}>{renderCardContent()}</Link> : renderCardContent()}
			{content && (
				<Box sx={contentSx}>
					<Divider sx={dividerSx} />
					{content}
				</Box>
			)}
		</Paper>
	);
};

IconCard.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconCard;
