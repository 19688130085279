const translations = {
    resources: {
        invoices: {
            name: 'Facture |||| Factures'
        },
        leaves: {
            name: 'Congé |||| Congés',
            fields: {
                start_date: 'Date de début',
                end_date: 'Date de fin',
                duration: 'Durée',
                status: 'Statut',
                comments: 'Commentaires',
                user_id: 'Utilisateur'
            }
        },
        customers: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom',
                short_name: 'Nom court'
            }
        },
        projects: {
            name: 'Projet |||| Projets',
            fields: {
                name: 'Nom',
                status: 'Statut',
                color: 'Couleur',
                customer_id: 'Client',
                start_date: 'Date de début',
                end_date: 'Date de fin',
                category: 'Catégorie'
            }
        },
        time_entries: {
            name: 'Timesheet |||| Timesheet',
            fields: {
                hours: 'Heures',
                invoiced: 'Facturé'
            }
        },
        time_entry_types: {
            name: 'Type timesheet |||| Types timesheet',
            fields: {
                name: 'Nom'
            }
        },
        weeklies: {
            fields: {
                workload: 'Charge de travail',
                priorities: 'Priorités',
                tasks: 'Tâches',
                created_at: 'Créé le'
            }
        },
        suggestions: {
            fields: {
                title: 'Titre',
                category: 'Catégorie',
                status: 'Statut',
                created_at: 'Créé le'
            }
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                active: 'Actif',
                email: 'E-mail',
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe',
                trigram: 'Trigramme',
                color: 'Couleur'
            }
        },
        account: {
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe'
            }
        }
    },
    'User': 'Utilisateur',
    'Name': 'Nom',
    'Number of days': 'Nombre de jours',
    'Search': 'Recherche',
    'Account': 'Compte',
    'Account updated': 'Compte mis à jour',
    'Leave validation': 'Validation du congé',
    'Other leaves at the same period': 'Autres congés à la même période',
    'Pending': 'En attente',
    'Accepted': 'Accepté',
    'Refused': 'Refusé',
    'Leave': 'Congé',
    'Recup': 'Récup',
    'Sick leave': 'Congé maladie',
    'Unpaid leave': 'Congé sans solde',
    'Other': 'Autre',
    'Useful links': 'Liens utiles',
    'Leaves': 'Congés',
    'Must be from the same month': 'Doit être du même mois',
    'Pending leaves': 'Congés en attente',
    'Current month': 'Mois courant',
    'This month': 'Ce mois-ci',
    'Next month': 'Mois prochain',
    'Last month': 'Mois précédent',
    'This year': 'Cette année',
    'Last year': 'L\'année dernière',
    'Reset': 'Réinitialiser',
    'Total leave taken': 'Total congés pris',
    'Total remaining leave': 'Total congés restants',
    'Recuperation days': 'Jours de récupération',
    'Other leave days': 'Autres jours de congés',
    'leaves.unpaid': 'Sans solde',
    'leaves.sick': 'Maladie',
    'Unpaid leave days': 'Congés sans solde',
    'Customer': 'Client',
    'Users': 'Utilisateurs',
    'Active': 'Actif',
    'Maintenance': 'Maintenance',
    'Paused': 'En pause',
    'Done': 'Fini',
    'Late': 'En retard',
    'Cancelled': 'Annulé',
    'Summary': 'Résumé',
    'Projects': 'Projets',
    'Project': 'Projet',
    'My projects only': 'Mes projets uniquement',
    'Hours': 'Heures',
    'Help': 'Aide',
    'Minimum duration of 0.25h': 'Durée minimum de 0,25h',
    '0.25h = 15 minutes': '0,25h = 15 minutes',
    'Set invoiced': 'Facturé(s)',
    'Last time entry': 'Dernière entrée timesheet',
    'Capacity': 'Capacité',
    'Last 7 days timesheet': 'Timesheet des 7 derniers jours',
    'Next leaves': 'Prochains congés',
    'My projects': 'Mes projets',
    'Links': 'Liens',
    'Last time entries': 'Dernières entrées timesheet',
    'Available': 'Disponible',
    'Low': 'Bas',
    'Normal': 'Normal',
    'High': 'Haut',
    'Overloaded': 'Surchargé',
    'Blocked': 'Bloqué',
    'My documents': 'Mes documents',
    'Priorities': 'Priorités',
    'Tasks': 'Tâches',
    'Last weekly': 'Dernier weekly',
    'Last monthly': 'Dernier monthly',
    'Never': 'Jamais',
    'See more': 'Voir plus',
    'Last Redmine tickets': 'Derniers tickets Redmine',
    'Accept': 'Accepter',
    'Refuse': 'Refuser',
    'Achievements': 'Succès',
    'Disciple of Nurgle': 'Disciple de Nurgle',
    'Took one day\'s sick leave': 'A pris un jour de congé maladie',
    'Took part in many meetings': 'A participé à beaucoup de réunions',
    'Took part in many calls': 'A participé à beaucoup d\'appels',
    'Involved in many projects': 'Impliqué dans de nombreux projets',
    'Has filled his timesheet many times': 'A rempli sa timesheet de nombreuses fois',
    'Has filled his timesheet many times with more than 8 hours a day': 'A rempli sa timesheet de nombreuses fois avec plus de 8h par jour',
    'More work?': 'Encore du travail?',
    'Pain reinforces devotion': 'La douleur renforce la dévotion',
    'Time is an illusion. Lunchtime doubly so.': 'Le temps est une illusion. En particulier l\'heure du déjeuner.',
    'Marathoner': 'Marathonien',
    'Underwent very long meetings': 'A subi de très longues réunions',
    'Hang by a thread': 'Ne tenir qu\'à un fil',
    'Month': 'Mois',
    'January': 'Janvier',
    'February': 'Février',
    'March': 'Mars',
    'April': 'Avril',
    'May': 'Mai',
    'June': 'Juin',
    'July': 'Juillet',
    'August': 'Août',
    'September': 'Septembre',
    'October': 'Octobre',
    'November': 'Novembre',
    'December': 'Décembre',
    'Ideas box': 'Boîte à idées',
    'Comments': 'Commentaires',
    'Comment': 'Commentaire',
    'Comment added': 'Commentaire ajouté',
    'Comment could not be added': 'Le commentaire n\'a pas pu être ajouté',
    'Add': 'Ajouter'
};

export default translations;
