import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'leave', name: 'Leave' },
	{ id: 'recup', name: 'Recup' },
	{ id: 'sick', name: 'Sick leave' },
	{ id: 'unpaid', name: 'Unpaid leave' },
	{ id: 'other', name: 'Other' },
];

const TypeInput = ({ classes, source = 'type', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default TypeInput;
