import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, minLength } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import ColorInput from '../common/inputs/ColorInput';

const optionText = choice => choice.firstname || choice.lastname ? `${choice.firstname} ${choice.lastname}` : '';

const UserEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
			<BooleanInput source="active" />
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<TextInput source="trigram" />
			<TextInput source="password" validate={[minLength(8)]} />
			<TextInput source="documents_url" />
			<ColorInput sx={{ marginBottom: '16px' }} />
			<ReferenceInput source="redmine_id" reference="redmine/users" sort={{ field: 'name', order: 'ASC' }}>
				<AutocompleteInput optionText={optionText} size="small" label="Redmine user" />
			</ReferenceInput>
			<BooleanInput source="is_admin" label="Admin" />
			<BooleanInput source="is_staff" label="Staff" />
		</SimpleForm>
	</Edit>
);

export default UserEdit;