import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'kernel', name: 'Kernel' },
	{ id: 'team', name: 'Team' }
];

const CategoryInput = ({ classes, source = 'category', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default CategoryInput;
