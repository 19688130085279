import LightbulbIcon from '@mui/icons-material/Lightbulb';

import SuggestionList from './SuggestionList';
import SuggestionEdit from './SuggestionEdit';
import SuggestionCreate from './SuggestionCreate';
import SuggestionShow from './SuggestionShow';


const suggestions = {
	list: SuggestionList,
	edit: SuggestionEdit,
	create: SuggestionCreate,
	show: SuggestionShow,
	icon: LightbulbIcon
};

export default suggestions;
