import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';

import Create from '../common/views/Create';
import RichTextInput, { FullRichTextInputToolbar } from '../common/inputs/RichTextInput';
import CategoryInput from './inputs/CategoryInput';

const SuggestionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <CategoryInput />
            <TextInput source="title" fullWidth sx={{ maxWidth: '100%' }} />
            <RichTextInput source="description" toolbar={<FullRichTextInputToolbar />} />
        </SimpleForm>
    </Create>
);

export default SuggestionCreate;
