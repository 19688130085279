import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';


const CategoryField = ({ source = 'type' }) => {
	const record = useRecordContext();
	const translate = useTranslate();

	const types = {
		'kernel': translate('Kernel'),
		'team': translate('Team')
	};

	if(!record) {
		return null;
	}

	const key = record ? record[source] : null;

	return (
		<Typography variant="body2">{types[key]}</Typography>
	);
};

export default CategoryField;
