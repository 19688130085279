import React, { useState, useEffect } from 'react';
import { ReferenceInput, AutocompleteInput, DateInput, List, ListBase, TopToolbar, usePermissions, useTranslate, useGetIdentity, useStore, SelectInput, Form } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { DefaultListActions } from '../../common/views/List';
import IconCard from '../../common/IconCard';
import SplitButton from '../../common/buttons/SplitButton';
import StatusInput from '../inputs/StatusInput';
import TypeInput from '../inputs/TypeInput';
import LeaveRow from './LeaveRow';
import LeaveCalendar from './LeaveCalendar';

import { fetchUsersLeavesStats, fetchUserLeavesStats } from '../../../services/api';

const OFFICIAL_DAYS = 20;
const EXTRA_DAYS = 6;
const CURRENT_YEAR = new Date().getFullYear();

const userOptionText = choice => choice?.fullname ? choice.fullname : '';
const monthChoices = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
];

const buttonGroupSx = { marginBottom: '-2px' };
const inactiveButtonSx = { color: (theme) => theme.palette.action.disabled };


const DateButtons = () => {
    const [currentButton, setCurrentButton] = useState();
    const { setValue } = useFormContext();
    const translate = useTranslate();

    const handleClickThisMonth = () => {
        setValue('start_date', moment().startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().endOf('month').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickThisMonth}>{translate('This month')}</Button>);
    };

    const handleClickNextMonth = () => {
        setValue('start_date', moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickNextMonth}>{translate('Next month')}</Button>);
    };

    const handleClickLastMonth = () => {
        setValue('start_date', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>);
    };

    const handleClickThisYear = () => {
        setValue('start_date', moment().startOf('year').format('YYYY-MM-DD'));
        setValue('end_date', moment().endOf('year').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickThisYear}>{translate('This year')}</Button>);
    };

    const handleClickLastYear = () => {
        setValue('start_date', moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'));
        setValue('end_date', moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickLastYear}>{translate('Last year')}</Button>);
    };

    const handleClickReset = () => {
        setValue('start_date', null);
        setValue('end_date', null);
    };

    return (
        <SplitButton closeOnButtonClick sx={{ height: '40px' }} currentButton={currentButton}>
            <Button onClick={handleClickThisMonth}>{translate('This month')}</Button>
            <Button onClick={handleClickNextMonth}>{translate('Next month')}</Button>
            <Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>
            <Button onClick={handleClickThisYear}>{translate('This year')}</Button>
            <Button onClick={handleClickLastYear}>{translate('Last year')}</Button>
            <Button onClick={handleClickReset}>{translate('Reset')}</Button>
        </SplitButton>
    );
};

const MonthSelect = ({ setValue }) => {
    const handleChangeMonth = (e) => {
        setValue(e.target.value);
    };

    return (
        <SelectInput source="month" label="Month" onChange={handleChangeMonth} choices={monthChoices} helperText={false} sx={{ margin: 0 }} />
    );
};

const YearButtons = ({ setValue }) => {
    const [currentButton, setCurrentButton] = useState();
    const translate = useTranslate();

    // TODO: get years from back later?
    const years = Array.from({ length: 3 }, (_, i) => CURRENT_YEAR - 1 - i);

    const handleClickYear = (e, year) => {
        setValue(year);
        setCurrentButton(<Button onClick={year ? (e) => handleClickYear(e, year) : handleClickYear}>{year ? year : translate('This year')}</Button>);
    };

    return (
        <SplitButton closeOnButtonClick sx={{ height: '40px' }} currentButton={currentButton}>
            <Button onClick={handleClickYear}>{translate('This year')}</Button>
            {years?.map(year => (
                <Button onClick={(e) => handleClickYear(e, year)} key={`btn_${year}`}>{year}</Button>
            ))}
        </SplitButton>
    );
};

const leaveFilters = [
    <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={userOptionText} size="small" />
    </ReferenceInput>,
    <TypeInput source="type" alwaysOn />,
    <StatusInput source="status" alwaysOn />,
    <DateInput source="start_date" alwaysOn />,
    <DateInput source="end_date" alwaysOn />,
    <DateButtons source="date" alwaysOn />
];

const AdminLeaveView = ({ year, month }) => {
    const [users, setUsers] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchUsersLeavesStats({ year, month });
            if (data?.length > 0) {
                setUsers(data);
            }
        }

        fetchStats();
    }, [year, month]);

    return (
        <Grid container spacing={2}>
            {users?.map(user => (
                <Grid item xl={2} lg={3} sm={6} xs={12} key={`user_leave_${user.id}`}>
                    <IconCard title={user.trigram} value={month ? (user?.taken_leaves || '0') : `${user?.taken_leaves || 0}/${OFFICIAL_DAYS + EXTRA_DAYS}`} icon={null} variant="rounded" paperSx={{ height: '100%' }}>
                        <Box sx={{ marginTop: '10px' }}>
                            {(!month && user?.current_month_leaves >= 0) && <Typography sx={{ marginTop: '10px' }}>{translate('Current month')}: {user.current_month_leaves}</Typography>}
                            {user?.taken_recup_leaves > 0 && <Typography >{translate('Recup')}: {user.taken_recup_leaves}</Typography>}
                            {user?.taken_sick_leaves > 0 && <Typography >{translate('leaves.sick', { _: 'Sick' })}: {user.taken_sick_leaves}</Typography>}
                            {user?.taken_unpaid_leaves > 0 && <Typography >{translate('leaves.unpaid', { _: 'Unpaid' })}: {user.taken_unpaid_leaves}</Typography>}
                            {user?.taken_other_leaves > 0 && <Typography >{translate('Other')}: {user.taken_other_leaves}</Typography>}
                        </Box>
                    </IconCard>
                </Grid>
            ))}
        </Grid>
    );
};

const PersonalLeaveView = ({ year }) => {
    const { data: account } = useGetIdentity();
    const [user, setUser] = useState(account);
    const translate = useTranslate();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchUserLeavesStats(account.id, { year });
            setUser(data);
        }

        if (account?.id) {
            fetchStats();
        }
    }, [account, year]);

    return (
        <Grid container spacing={2}>
            {year ? null : (
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title={translate('Current month')} value={user?.current_month_leaves || '0'} icon={<CalendarMonthIcon />} variant="rounded" />
                </Grid>
            )}
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title={translate('Total leave taken')} value={`${user?.taken_leaves || 0}/26`} icon={<EventBusyIcon />} variant="rounded" />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title={translate('Total remaining leave')} value={`${user?.remaining_leaves || 0}/26`} icon={<EventAvailableIcon />} variant="rounded" />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    {user?.taken_sick_leaves > 0 && <Typography color="text.secondary">{translate('Sick leave days')}: {user?.taken_sick_leaves || 0}</Typography>}
                    {user?.taken_unpaid_leaves > 0 && <Typography color="text.secondary">{translate('Unpaid leave days')}: {user?.taken_unpaid_leaves || 0}</Typography>}
                    {user?.taken_recup_leaves > 0 && <Typography color="text.secondary">{translate('Recuperation days')}: {user?.taken_recup_leaves || 0}</Typography>}
                    {user?.taken_other_leaves > 0 && <Typography color="text.secondary">{translate('Other leave days')}: {user?.taken_other_leaves || 0}</Typography>}
                </Box>
            </Grid>
        </Grid>
    );
};

const ViewButton = ({ viewCalendar, setViewCalendar }) => {
    const handleClickList = () => {
        setViewCalendar(false);
    };

    const handleClickGrid = () => {
        setViewCalendar(true);
    };

    return (
        <ButtonGroup size="small" variant="text" sx={buttonGroupSx}>
            <Button onClick={handleClickList} sx={viewCalendar ? inactiveButtonSx : {}}><ViewHeadlineIcon /></Button>
            <Button onClick={handleClickGrid} sx={viewCalendar ? {} : inactiveButtonSx}><CalendarMonthIcon /></Button>
        </ButtonGroup>
    );
};

const CustomList = (props) => {
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [viewCalendar, setViewCalendar] = useStore('leaves.list.viewCalendar', false);
    const { permissions } = usePermissions();

    return (
        <Box my={3}>
            <ListBase {...props} >
                <Box mb={3}>
                    <Form>
                        <Box display="flex" justifyContent="flex-end" mb={1} gap={1}>
                            {permissions?.includes('is_admin') && <MonthSelect value={month} setValue={setMonth} />}
                            <YearButtons value={year} setValue={setYear} />
                        </Box>
                    </Form>
                    {permissions?.includes('is_admin') ? (
                        <AdminLeaveView year={year} month={month} />
                    ) : (
                        <PersonalLeaveView year={year} />
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ViewButton viewCalendar={viewCalendar} setViewCalendar={setViewCalendar} />
                </Box>
                {viewCalendar ? (
                    <LeaveCalendar />
                ) : (
                    <List {...props} >
                        <LeaveRow />
                    </List>
                )}
            </ListBase>
        </Box>
    );
};

const LeaveList = props => (
    <CustomList {...props} filters={leaveFilters} sort={{ field: 'id', order: 'DESC' }} actions={<TopToolbar><DefaultListActions /></TopToolbar>} perPage={25} />
);

export default LeaveList;