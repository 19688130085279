import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'new', name: 'New' },
	{ id: 'refused', name: 'Refused' },
	{ id: 'accepted', name: 'Accepted' }
];

const StatusInput = ({ classes, source = 'status', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusInput;
